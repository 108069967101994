import { CommonModule } from '@angular/common';
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit,
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { TextPipe } from 'src/app/presentation/pages/shared/pipes/text.pipe';
import { IBrandVM } from 'src/app/presentation/view-models';

type LogoColor = { textColor: string; background: string };

@Component({
	selector: 'brand-logo',
	templateUrl: './brand-logo.component.html',
	styleUrls: ['./brand-logo.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule, TextPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandLogoComponent implements OnInit {
	@Input() public isSmall?: boolean = false;
	@Input() public brand: IBrandVM;

	public logoColor?: LogoColor;
	public shortBrandName?: string;

	public ngOnInit(): void {
		const hasLogo = !!this.brand?.logoUrl;
		if (!hasLogo) {
			this.shortBrandName = this.getShortBrandName(this.brand?.name);
		}
	}

	private getShortBrandName(name: string): string {
		return name.split(' ').join('')[0];
	}
}
