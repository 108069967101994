import { Injectable } from '@angular/core';
import { IAd } from 'src/app/core/models';
import { IAdPlacement } from 'src/app/core/models/ad/ad/ad-placement.model';
import { CreativesAdMapperService } from 'src/app/features/ad/mappers/ad-mapper/creatives-ad.mapper';
import { FacebookAdContentIdEnum } from 'src/app/presentation/features/integrations/facebook/ad/enums/fb-ad-content-id.enum';
import { FacebookCarouselSupportedPlacements } from 'src/app/presentation/features/integrations/facebook/placement/carousel/placements/enums';
import {
	AdContentIdEnum,
	FacebookSupportedPlacementIdsOrder,
	FacebookSupportedPlacements,
} from 'src/app/presentation/features/integrations/facebook/placement/single/placements/shared/enums';
import {
	AdFormatEnum,
	IAdListItemVM,
	IAdPlacementChildVM,
	IAssignedCreativeVM,
	MediaTypeEnum,
} from 'src/app/presentation/view-models';

@Injectable({
	providedIn: 'root',
})
export class FacebookAdListMappersService {
	constructor(public creativesMapper: CreativesAdMapperService) {}

	public toAdListVM(ad: IAd): IAdListItemVM {
		const adListVM: IAdListItemVM = {} as IAdListItemVM;
		const setIdValues = [];

		adListVM.id = ad.id;
		adListVM.adFormatId = ad.adFormatId;
		adListVM.adGroupId = ad.adGroupId;
		adListVM.languageId = ad.languageId;
		adListVM.name = ad.name;

		adListVM.primaryText =
			ad.default.content?.[FacebookAdContentIdEnum.primaryText] ||
			ad.default.content?.[FacebookAdContentIdEnum.primaryTexts]?.[0];
		adListVM.primaryTexts =
			ad.default.content?.[FacebookAdContentIdEnum.primaryTexts];
		adListVM.url = ad.default.content?.[FacebookAdContentIdEnum.websiteUrl];

		if (ad.default?.creatives) {
			if (
				ad.adFormatId === AdFormatEnum.carousel &&
				ad.default.cards?.[0]?.creatives
			) {
				adListVM.adCreatives = ad.default.cards[0].creatives.map(
					(creative) => this.creativesMapper.toCreativeVM(creative),
				);
			} else {
				adListVM.adCreatives = ad.default.creatives.map((creative) =>
					this.creativesMapper.toCreativeVM(creative),
				);
			}
		}

		if (ad.adFormatId === AdFormatEnum.carousel) {
			ad.default?.cards.forEach((cardCreative) => {
				cardCreative.creatives.forEach((creative) =>
					setIdValues.push(creative?.setId),
				);
			});
			adListVM.cardCreativesIds = setIdValues;
			adListVM.websiteUrls = ad.default?.cards.map(
				(cardContent) =>
					cardContent?.content?.[AdContentIdEnum.websiteUrl],
			);
		}

		adListVM.url =
			ad.adFormatId === AdFormatEnum.carousel
				? ad.default.content[AdContentIdEnum.seeMoreUrl] ??
					ad.default.content[AdContentIdEnum.appUrl]
				: ad.default.content[AdContentIdEnum.websiteUrl] ??
					ad.default.content[AdContentIdEnum.appUrl];

		if (ad.default.content[AdContentIdEnum.appUrl]) {
			adListVM.websiteUrls = [];
		}

		adListVM.children = this.fromAdToChildrenVM(ad);

		return adListVM;
	}

	private fromAdToChildrenVM(ad: IAd): IAdPlacementChildVM[] {
		const children: IAdPlacementChildVM[] = [];
		const sortedAdplacements = this.sortAdPlacements(ad.placements);
		const filterForSupportedDesign = this.getOnlyDesignedPlacements(
			sortedAdplacements,
			ad.adFormatId,
		);

		if (filterForSupportedDesign) {
			filterForSupportedDesign.forEach((placement) => {
				children.push(this.toAdChildVM(ad, placement));
			});
		}

		return children;
	}

	private toAdChildVM(ad: IAd, placement: IAdPlacement): IAdPlacementChildVM {
		return {
			adId: ad.id,
			placementId: placement.placementId,
			isCustomized: placement?.isCustomized,
			adCreatives: this.mapCreatives(ad.adFormatId, placement),
			mediaType: this.mapMediaType(
				placement?.creative?.renderingOption,
				ad.adFormatId,
			),
			languageId: ad.languageId,
			primaryText:
				placement.content[AdContentIdEnum.primaryText] ||
				placement.content[AdContentIdEnum.primaryTexts]?.[0],
			primaryTexts: placement.content
				? placement.content[AdContentIdEnum.primaryTexts]
				: undefined,
			url: placement.content
				? this.mapUrl(ad.adFormatId, placement)
				: undefined,
			websiteUrls: ad.default.cards
				? ad.default.cards.map(
						(cardContent) =>
							cardContent?.content?.[AdContentIdEnum.websiteUrl],
					)
				: undefined,
		};
	}

	private mapUrl(adFormatId: AdFormatEnum, placement: IAdPlacement): string {
		if (adFormatId === AdFormatEnum.carousel) {
			return placement?.content[AdContentIdEnum.seeMoreUrl];
		}
		if (adFormatId === AdFormatEnum.singleImageAndVideo) {
			return placement?.content[AdContentIdEnum.websiteUrl];
		}
		return undefined;
	}

	private mapMediaType(
		renderingOptionTypeId: string,
		adFormatId: AdFormatEnum,
	): MediaTypeEnum {
		if (adFormatId === AdFormatEnum.carousel) {
			return MediaTypeEnum.Carousel;
		} else {
			switch (renderingOptionTypeId) {
				case MediaTypeEnum.Image:
					return MediaTypeEnum.Image;
				case MediaTypeEnum.Video:
					return MediaTypeEnum.Video;
				default:
					return;
			}
		}
	}

	private mapCreatives(
		adFormatId: AdFormatEnum,
		placement: IAdPlacement,
	): IAssignedCreativeVM[] {
		if (adFormatId === AdFormatEnum.carousel && placement.cards?.length) {
			return placement.cards.map((card) =>
				this.creativesMapper.toCreativeVM(card.creative),
			);
		}
		if (
			adFormatId === AdFormatEnum.singleImageAndVideo &&
			placement.creative
		) {
			const creativeVM = this.creativesMapper.toCreativeVM(
				placement.creative,
			);
			return creativeVM ? [creativeVM] : [];
		}
		return [];
	}

	public sortAdPlacements(adPlacements: IAdPlacement[]): IAdPlacement[] {
		if (!adPlacements?.sort) {
			return [];
		}

		return adPlacements
			.slice()
			.sort(
				(a, b) =>
					FacebookSupportedPlacementIdsOrder[a.placementId] -
					FacebookSupportedPlacementIdsOrder[b.placementId],
			);
	}

	private getOnlyDesignedPlacements(
		adPlacements: IAdPlacement[],
		adformat: AdFormatEnum,
	): IAdPlacement[] {
		let availablePlacementsForDesign: string[];

		switch (adformat) {
			case AdFormatEnum.singleImageAndVideo:
				availablePlacementsForDesign = Object.values(
					FacebookSupportedPlacements,
				) as string[];
				break;
			case AdFormatEnum.carousel:
				availablePlacementsForDesign = Object.values(
					FacebookCarouselSupportedPlacements,
				) as string[];
				break;
		}

		return adPlacements.filter((placement) =>
			availablePlacementsForDesign?.includes(placement.placementId),
		);
	}
}
