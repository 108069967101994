import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config/config.service';
import { KnownUserFeature } from 'src/app/features/account/services/localization/localization-base.service';
import { AppFeatureServices } from 'src/app/features/app-feature.service';

@Injectable({
	providedIn: 'root',
})
export class AppIntializerService {
	constructor(private appFeatureServices: AppFeatureServices) {}

	public appInit(route: ActivatedRouteSnapshot): Observable<boolean> {
		const accountSlug: string =
			route.paramMap.get('accountSlug') || undefined;
		const brandSlug: string = route.paramMap.get('brandSlug') || undefined;
		const campaignId: string =
			route.firstChild.paramMap.get('campaignId') || undefined;

		this.appFeatureServices.accountFeature.user.getWithQuery({
			accountSlug: accountSlug,
			brandSlug: brandSlug,
		});

		return this.appFeatureServices.accountFeature.user
			.hasFeature(KnownUserFeature.SocialCampaignManager)
			.pipe(
				map((isSocialFeature) => {
					if (isSocialFeature) {
						this.appFeatureServices.accountFeature.localization.getWithQuery(
							{
								accountSlug: accountSlug,
								brandSlug: brandSlug,
							},
						);
						this.appFeatureServices.accountFeature.timezone.getAll();
						this.appFeatureServices.accountFeature.network.getAll();
						this.appFeatureServices.tiktokFeature.adformat.getAll();
						this.appFeatureServices.facebookFeature.adformat.getAll();
						this.appFeatureServices.linkedInFeature.adformat.getAll();
						this.appFeatureServices.facebookFeature.placement.single.getPlacements();
						this.appFeatureServices.facebookFeature.placement.carousel.getPlacements();

						this.appFeatureServices.tiktokFeature.placement.singleImage.getPlacements();
						this.appFeatureServices.tiktokFeature.placement.singleVideo.getPlacements();

						this.appFeatureServices.linkedInFeature.placement.singleImage.getPlacements();
						this.appFeatureServices.linkedInFeature.placement.singleVideo.getPlacements();

						if (campaignId) {
							this.appFeatureServices.adFeature.adGroup.getAdGroupsByCampaignId(
								campaignId,
							);
						}

						this.appFeatureServices.accountFeature.integration.getAll();

						if (campaignId) {
							this.appFeatureServices.campaignFeature.campaign.getById(
								campaignId,
							);
						}

						return true;
					}
					window.location.href = ConfigService.config.B2_URL;
				}),
			);
	}
}
