import { Injectable, Injector } from '@angular/core';
import { LinkedInAdAccountService } from './services/ad-account/in-ad-account.service';
import { LinkedInCampaignService } from './services/campaign/in-campaign.service';
import { LinkedInCampaignGroupService } from './services/campaign-group/in-campaign-group.service';
import { LinkedInCampaignPlacementService } from './services/campaign-placement/in-campaign-placement.service';

@Injectable()
export class LinkedInAccountFeatureService {
	public adAccount: LinkedInAdAccountService;
	public campaign: LinkedInCampaignService;
	public campaignGroup: LinkedInCampaignGroupService;
	public campaignPlacement: LinkedInCampaignPlacementService;

	constructor(private injector: Injector) {
		this.adAccount = this.injector.get(LinkedInAdAccountService);
		this.campaign = this.injector.get(LinkedInCampaignService);
		this.campaignGroup = this.injector.get(LinkedInCampaignGroupService);
		this.campaignPlacement = this.injector.get(
			LinkedInCampaignPlacementService,
		);
	}
}
